import { Config } from "../base/portal_env_config/config";
import AuthTypes from "./auth.types";

const initialState: any = {
  token: null,
  portal: { ...Config.portal, brandId: Config.portal.appId },
  userDetails: null,
  lisUserDetails: null
};

const authReducer = (state = initialState, action: any) => {
  let userDetails;
  let lisUserDetails;
  switch (action.type) {
    case AuthTypes.SET_USER_DETAILS:
      userDetails = action.payload;
      return {
        ...state,
        userDetails,
      };
    case AuthTypes.SET_BRAND_ID:
      return {
        ...state,
        portal: {
          ...state.portal,
          brandId: +action.payload,
        },
      };
    case AuthTypes.SET_LOGIN_RESPONSE:
      {
        let userData = action?.payload;
        if (typeof userData === 'string' || userData instanceof String) {
          userData = JSON.parse(action?.payload);
        }
        return {
          ...state,
          token: userData?.sessionToken || "",
          userDetails: {
            ...userData,
          },
          isLoggedIn: true,
        };
      }
    case AuthTypes.LAST_LOGIN_PAGE_TIME:
      return {
        ...state,
        loginTime: {
          lastLoginPageTime: state?.loginTime?.recentLoginPageTime,
          recentLoginPageTime: action?.payload,
        },
      };
    case AuthTypes.SET_LOGOUT:
      return {
        ...state,
        token: "",
        userDetails: undefined,
        isLoggedIn: false,
      };
    case AuthTypes.SET_BRAND_ID:
      return {
        ...state,
        portal: {
          ...state.portal,
          brandId: +action.payload,
        },
      };
    case AuthTypes.UPDATE_USER_DETAILS:
      userDetails = {
        ...state.userDetails,
        ...action.payload,
      };
      return {
        ...state,
        userDetails,
      };
    case AuthTypes.SET_LIS_USER_DETAILS:
      lisUserDetails = action.payload;
      return {
        ...state,
        lisUserDetails,
      };
    case "setauthInitialState": //use this for modifying localstorage save behaviour
      return {
        ...action.payload,
        portal: {
          ...state.portal,
          brandId: action.payload.portal.brandId,
        },
      }; //`set${reucerNameInCombineReducer}InitialState` is used to set initial state of reducer
    default:
      return state;
  }
};

export default authReducer;
