import { healthLockerReducer } from '../../components/healthlocker/healthlocker.redux';
import { hsLocReducer } from './../../components/util-components/hs-location/hs-location.redux';
import { combineReducers } from 'redux';
import authReducer from '../../auth/auth.reducer';
import packagesReducers from '../../components/packages/packages.reducers';
import diagnosticReducer from '../../components/diagnostics/diagnostic.reducers';
import checkoutReducers from '../../components/checkout/checkout.reducers';
import layoutReducers from '../../components/layout/layout.reducers';
import videocardReducers from '../../components/video/videocard.reducers';
import { pagemoduleReducer } from '../../base/pagemoduleutil/pagemodule.redux';
import { consultationsReducer } from '../../components/consultations/consultations.redux';
import dashboardReducer from '../../components/dashboard/dashboard.reducers';
import profileReducer from '../../components/profile/profile.reducers';
import faqsReducer from '../../components/faqs/faqs.reducers';
import orderHistoryReducer from '../../components/orderhistory/orderhistory.reducers';
import { walletReducer } from '../../components/wallet/wallet.redux';
import { campaignUserReducer } from '../../components/campaign/campaign.redux';
import { findacentreSearchReducer } from '../../components/diagnostics/diagnosticcentres/findacentre.redux';


const appReducer = combineReducers({
  auth: authReducer,
  layout: layoutReducers,
  package: packagesReducers,
  cart: checkoutReducers,
  diagnostic: diagnosticReducer,
  location: hsLocReducer,
  healthlocker: healthLockerReducer,
  video: videocardReducers,
  basepagemodule: pagemoduleReducer,
  consultations: consultationsReducer,
  dashboard: dashboardReducer,
  profile: profileReducer,
  faq: faqsReducer,
  orderhistory: orderHistoryReducer,
  wallet: walletReducer,
  campaignUser: campaignUserReducer,
  findacentersearchdetails:findacentreSearchReducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_LOGGED_OUT'  && !state.profile.isAccountDeleted) {
    const { auth, basepagemodule, layout, location,dashboard } = state;
    state = { auth, basepagemodule, layout, location,dashboard };
  }

  return appReducer(state, action);
};

export default rootReducer;
