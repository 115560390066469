import { connect } from 'react-redux';
import layoutActions from '../layout/layout.actions';


const initialState = { testreports: [] };
export const consultationsType = {
    FETCH_UPCOMMING_CONSULTS: "FETCH_UPCOMMING_CONSULTS",
    FETCH_PAST_CONSULTS: "FETCH_PAST_CONSULTS",
    FETCH_REPORTDETAILS: "FETCH_REPORTDETAILS",
    FETCH_VITALDETAILS: "FETCH_VITALDETAILS",
    FETCH_GRAPH_DETAILS: "FETCH_GRAPH_DETAILS",
    FETCH_SELECTED_USERDETAILS: "FETCH_SELECTED_USERDETAILS",
    FETCH_VDC_GRAPH_DETAILS: "FETCH_VDC_GRAPH_DETAILS"
}
export const consultationsAction = {
    setUpcommingConsList(item: any) {
        return (dispatch: any) => {
            dispatch({ type: consultationsType.FETCH_UPCOMMING_CONSULTS, payload: item });
        }
    },
    setPastConsList(item: any) {
        return (dispatch: any) => {
            dispatch({ type: consultationsType.FETCH_PAST_CONSULTS, payload: item });
        }
    },
    setSelectedUserDetails(item: any) {
        return (dispatch: any) => {
            dispatch({ type: consultationsType.FETCH_SELECTED_USERDETAILS, payload: item });
        }
    },
    setReportList(item: any) {
        return (dispatch: any) => {
            dispatch({ type: consultationsType.FETCH_REPORTDETAILS, payload: item });
        }
    },
    setVitalList(item: any) {
        return (dispatch: any) => {
            dispatch({ type: consultationsType.FETCH_VITALDETAILS, payload: item });
        }
    },
    setGraphResponse(item: any) {
        return (dispatch: any) => {
            dispatch({ type: consultationsType.FETCH_GRAPH_DETAILS, payload: item });
        }
    },
    setVDCGraphResponse(item: any) {
        return (dispatch: any) => {
            dispatch({ type: consultationsType.FETCH_VDC_GRAPH_DETAILS, payload: item });
        }
    }
}
export const consultationsReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case consultationsType.FETCH_UPCOMMING_CONSULTS:
            return {
                ...state,
                upcommingconslist: action.payload
            };
        case consultationsType.FETCH_PAST_CONSULTS:
            return {
                ...state,
                pastconslist: action.payload
            };
        case consultationsType.FETCH_SELECTED_USERDETAILS:
            return {
                ...state,
                selectedProfile: action.payload
            };
        case consultationsType.FETCH_REPORTDETAILS:
            return {
                ...state,
                reportlist: action.payload
            };
        case consultationsType.FETCH_VITALDETAILS:
            return {
                ...state,
                vitallist: action.payload
            };
        case consultationsType.FETCH_GRAPH_DETAILS:
            return {
                ...state,
                graphResponse: action.payload
            };
        case consultationsType.FETCH_VDC_GRAPH_DETAILS:
            return {
                ...state,
                vdcGraphResponse: action.payload
            }
        default:
            return state;
    }
};

export const connectConsultations = (Component) => {
    return connect(consultationsReducer, { ...consultationsAction, ...layoutActions })(Component)
};