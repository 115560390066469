import { connect } from 'react-redux';
import layoutActions from '../../components/layout/layout.actions';


const initialState = { userdetails: [] };
export const campaignuserdetails = {
    SET_CAPMAIGN_USER_DETAILS: "GET_USER_DETAILS",
}
export const campaignUserAction = {
    setCapmaignUserDetails(item: any) {
        return (dispatch: any) => {
            dispatch({ type: campaignuserdetails.SET_CAPMAIGN_USER_DETAILS, payload: item });
        }
    }
}
export const campaignUserReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case campaignuserdetails.SET_CAPMAIGN_USER_DETAILS:
            return {
                ...state,
                userdetails: action.payload
            };
        default:
            return state;
    }
};

export const connectCampaignUserDetails = (Component) => {
    return connect(campaignUserReducer, { ...campaignUserAction, ...layoutActions })(Component)
};