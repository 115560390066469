import { connect } from 'react-redux';
import layoutActions from '../../../components/layout/layout.actions';
import { LocationModeResponse } from '../../../model/common/locationmoderesponse';


const initialState = {
    searchdetails: {
        stateResponse: new Array<LocationModeResponse>(),
        cityResponse: new Array<LocationModeResponse>(),
        localityResponse: new Array<LocationModeResponse>(),
        stateId: 0,
        cityId: 0,
        areaId: 0,
        selectedCharacter: '',
        getCharFilterArray: true
    },

};
export const findacentresearchdetails = {
    SET_FINDACENTRE_SEARCH_DETAILS: "GET_USER_DETAILS",
}
export const findacentreSearchAction = {
    setfindacentreSearch(item: any) {
        return (dispatch: any) => {
            dispatch({ type: findacentresearchdetails.SET_FINDACENTRE_SEARCH_DETAILS, payload: item });
        }
    }
}
export const findacentreSearchReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case findacentresearchdetails.SET_FINDACENTRE_SEARCH_DETAILS:
            return {
                ...state,
                searchdetails: action.payload
            };
        default:
            return state;
    }
};

export const connectfindacentreSearchdetails = (Component) => {
    return connect(findacentreSearchReducer, { ...findacentreSearchAction, ...layoutActions })(Component)
};