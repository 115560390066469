import LayoutTypes from './layout.types';
import { Config } from '../../base/portal_env_config/config';
const disabled = true;
const initialState = {
    isLoading: false,
    navbar: {
        links: getNavbarLinks()
    },
    footer: {
        noOfLinks: 6,
        links: getFooterLinks()
    },
    profileMenu: {
        links: getProfileMenuLinks()
    },
}


const layoutReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case LayoutTypes.SET_FOOTER_LINKS:
            return {
                ...state,
                footer: {
                    ...state.footer,
                    links: action.payload
                }
            }
        case LayoutTypes.ADD_FOOTER_LINK:
            return {
                ...state,
                footer: {
                    ...state.footer,
                    links: [...state.footer.links, ...action.payload]
                }
            }
        case LayoutTypes.SET_NAVBAR_LINKS:
            return {
                ...state,
                navbar: {
                    ...state.navbar,
                    links: action.payload
                }
            }
        case LayoutTypes.ADD_NAVBAR_LINK:
            return {
                ...state,
                navbar: {
                    ...state.navbar,
                    links: [...state.navbar.links, ...action.payload]
                }
            }
        case LayoutTypes.SET_LAYOUT_SPINNER:
            return {
                ...state,
                isLoading: action.payload || false
            }
        case LayoutTypes.SET_IS_FROM_SMS_LINK:
            return {
                ...state,
                isFromSMSLinkGlobal: action.payload || false
            }
        // case 'setlayoutInitialState'://use this for modifying localstorage save behaviour
        //     return {
        //         ...action.payload,
        //         isLoading:false
        //     }//`set${reucerNameInCombineReducer}InitialState` is used to set initial state of reducer
        default:
            return state;
    }
};

function getFooterLinks() {
    return [
        {
            disabled,
            section: "Doctors",
            link: '/booking/doctor',
            subsections: [
                {
                    section: "General Physician",
                    link: '/booking/doctor?specializationId=0'
                },
                {
                    section: "Urologist",
                    link: '/booking/doctor?specializationId=1'
                },
                {
                    section: "Oncologist",
                    link: '/booking/doctor?specializationId=2'
                },
                {
                    section: "Urologist",
                    link: '/booking/doctor?specializationId=3'
                },
                {
                    section: "Dentist",
                    link: '/booking/doctor?specializationId=4'
                },
                {
                    section: "Dermatologist",
                    link: '/booking/doctor?specializationId=5'
                },
                {
                    section: "ENT",
                    link: '/booking/doctor?specializationId=6'
                }
            ]
        },
        {
            disabled,
            section: "Diagnostic Packages",
            link: '/diagnostic',
            subsections: [
                {
                    section: "Basic Health Package",
                    link: '/subscriptions/packagedetails?packageID=20'
                },
                {
                    section: "Cancer Profile - Male",
                    link: '/subscriptions/packagedetails?packageID=21'
                },
                {
                    section: "Cancer Profile - Female",
                    link: '/subscriptions/packagedetails?packageID=22'
                },
                {
                    section: "Complete Health Package with Vitamins",
                    link: '/subscriptions/packagedetails?packageID=23'
                },
                {
                    section: "Diabetic Package",
                    link: '/subscriptions/packagedetails?packageID=24'
                },
                {
                    section: "Vital Profile - Female",
                    link: '/subscriptions/packagedetails?packageID=25'
                }
            ]
        },
        {
            disabled,
            section: "Health Packages",
            link: "/subscriptions",
            subsections: [
                {
                    section: "Diabetes Care Package",
                    link: '/subscriptions/packagedetails?packageID=1'
                },
                {
                    section: "Gold Men care Plan",
                    link: '/subscriptions/packagedetails?packageID=2'
                },
                {
                    section: "25+ Preventive basic",
                    link: '/subscriptions/packagedetails?packageID=3'
                },
                {
                    section: "25+ Preventive Comprehensive",
                    link: '/subscriptions/packagedetails?packageID=4'
                },
                {
                    section: "Golden Age Screening",
                    link: '/subscriptions/packagedetails?packageID=5'
                },
                {
                    section: "Personal Doctor Package",
                    link: '/subscriptions/packagedetails?packageID=6'
                }
            ]
        },
        {
            disabled,
            section: "Buy Medicines",
            link: "/pharmacy",
            subsections: [
                {
                    section: "Browse Over the counter medicines",
                    link: '/pharmacy/countermedicines'
                },
                {
                    section: "Upload Prescriptions",
                    link: '/pharmacy/uploadPrescription'
                }
            ]
        },
        {
            disabled,
            section: "Book Appointment",
            link: "/specialization",
            subsections: []
        }
    ];
}

function getNavbarLinks() {
    //used for NavBar and Myconsultation checking for book now
    return [
        {
            section: "Home",
            translation_var: "HOME",
            link: '/',
            disabled: !Config.portal.menuConfig.HOME.enabled,
            priority: Config.portal.menuConfig.HOME.priority
        },
        {
            section: Config.portal.menuConfig.LAB_WALKIN.label ? Config.portal.menuConfig.LAB_WALKIN.label : "Lab Tests Walk-In",
            translation_var: Config.portal.menuConfig.LAB_WALKIN.translation_var ? Config.portal.menuConfig.LAB_WALKIN.translation_var : "LAB_WALKIN",
            link: '/test',
            disabled: !Config.portal.menuConfig.LAB_WALKIN.enabled,
            priority: Config.portal.menuConfig.LAB_WALKIN.priority
        },
        {
            section: Config.portal.menuConfig.LAB_HOME.label ? Config.portal.menuConfig.LAB_HOME.label : "Lab Tests Home Collection",
            translation_var: Config.portal.menuConfig.LAB_HOME.translation_var ? Config.portal.menuConfig.LAB_HOME.translation_var : "LAB_HOME_COLLECTION",
            link: '/diagnostic/home-sample-collection',
            disabled: !Config.portal.menuConfig.LAB_HOME.enabled,
            priority: Config.portal.menuConfig.LAB_HOME.priority
        },
        {
            section: "Doctor Consultations - InPerson",
            translation_var: Config.portal.menuConfig.DOCTOR_WALKIN.translation_var ? Config.portal.menuConfig.DOCTOR_WALKIN.translation_var : "DOCTOR_INPERSON",
            link: '/booking/inperson',
            disabled: !Config.portal.menuConfig.DOCTOR_WALKIN.enabled,
            priority: Config.portal.menuConfig.DOCTOR_WALKIN.priority
        },
        {
            section: "Doctor Consultations - Video",
            translation_var: Config.portal.menuConfig.DOCTOR_VIDEO.translation_var ? Config.portal.menuConfig.DOCTOR_VIDEO.translation_var : "DOCTOR_VIDEO",
            link: Config.portal.menuConfig.DOCTOR_VIDEO.landing_link ? Config.portal.menuConfig.DOCTOR_VIDEO.landing_link : '/booking/video',
            disabled: !Config.portal.menuConfig.DOCTOR_VIDEO.enabled,
            priority: Config.portal.menuConfig.DOCTOR_VIDEO.priority
        },
        {
            section: "Doctor Instant Connect",
            translation_var: Config.portal.menuConfig.DOCTOR_CONNECTNOW?.translation_var ? Config.portal.menuConfig.DOCTOR_CONNECTNOW.translation_var : "DOCTOR_CONNECTNOW",
            link: Config.portal.menuConfig.DOCTOR_CONNECTNOW?.landing_link ? Config.portal.menuConfig.DOCTOR_CONNECTNOW.landing_link : '/booking/livenow',
            disabled: !Config.portal.menuConfig.DOCTOR_CONNECTNOW?.enabled,
            priority: Config.portal.menuConfig.DOCTOR_CONNECTNOW?.priority
        },
        {
            section: "Buy Medicines",
            translation_var: Config.portal.menuConfig.PHARMACY.translation_var ? Config.portal.menuConfig.PHARMACY.translation_var : "BUY_MEDICINES",
            link: '/pharmacy',
            disabled: !Config.portal.menuConfig.PHARMACY.enabled,
            priority: Config.portal.menuConfig.PHARMACY.priority
        },
        {
            section: "Buy Products",
            translation_var: Config.portal.menuConfig.PRODUCT.translation_var ? Config.portal.menuConfig.PRODUCT.translation_var : "BUY_PRODUCTS",
            link: '/product',
            disabled: !Config.portal.menuConfig.PRODUCT.enabled,
            priority: Config.portal.menuConfig.PRODUCT.priority
        },
        {
            section: "Subscriptions",
            translation_var: "SUBSCRIPTIONS",
            link: '/subscriptions',
            disabled: !Config.portal.menuConfig.PACKAGES.enabled,
            priority: Config.portal.menuConfig.PACKAGES.priority
        },
        {
            section: "Wellness",
            translation_var: "WELLNESS",
            link: '/wellness',
            disabled: !Config.portal.menuConfig.WELLNESS.enabled,
            priority: Config.portal.menuConfig.WELLNESS.priority
        }
    ];
}
function getProfileMenuLinks() {
    let profileMenuList: any[] = [
        {
            section: "My Profile",
            link: "/profile/profiledetail",
            priority: 1,
        },
        // {
        //     section: "My Family",
        //     link: "/profile/profiledetail/familyprofile",
        //     priority: 2,
        // },

        {
            section: "My Dashboard",
            link: "/mydashboard",
            priority: 3,
        },
        {
            section: "My Wallet History",
            link: "/profile/wallet",
            priority: 3,
            disabled: !Config.portal.walletEnabled,
        },
        // {
        //     section: "Consultations",
        //     link: "/profile/consultations/myconsultations",
        //     disabled: !Config.portal.menuConfig.MENU_MYCONSULTS.enabled,
        //     priority: 4,
        // },
        // {
        //     section: "Reports",
        //     link: "/profile/consultations/myconsultationreports",
        //     disabled: !Config.portal.menuConfig.MENU_MYREPORTS.enabled,
        //     priority: 5
        // },
        {
            section: "Health Locker",
            link: "/profile/healthlocker?type=prescription",
            disabled: !Config.portal.menuConfig.MENU_HEALTHLOCKER.enabled,
            priority: 6,
        },
        // {
        //     section: "Order History",
        //     link: "/profile/orderhistory",
        //     priority: 7,
        // }
    ]
    // let profileMenuList: any[] = [
    //     {
    //         section: "About Us",
    //         link: "/profile/profiledetail",
    //         priority: 1,
    //     },
    //     {
    //         section: "Book a Test",
    //         link: "/profile/consultations/myconsultations",
    //         disabled: !Config.portal.menuConfig.MENU_MYCONSULTS.enabled,
    //         priority: 2,
    //     },
    //     {
    //         section: "Home Sample Collection",
    //         link: "/profile/consultations/myconsultationreports",
    //         disabled: !Config.portal.menuConfig.MENU_MYREPORTS.enabled,
    //         priority: 3
    //     },
    //     {
    //         section: "Health Locker",
    //         link: "/profile/healthlocker?type=prescription",
    //         disabled: !Config.portal.menuConfig.MENU_HEALTHLOCKER.enabled,
    //         priority: 4,
    //     },
    //     {
    //         section: "Health Records",
    //         link: "/profile/patientphr",
    //         priority: 5,
    //     },
    //     {
    //         section: "Order History",
    //         link: "/profile/orderhistory",
    //         priority: 5,
    //     }
    // ]
    return profileMenuList;
};

export default layoutReducer;