import CheckoutTypes from './checkout.types';
import { BasketRequest } from '../../model/basket/basketrequest';

const initialState = {
    basketRequest: new BasketRequest(),
    coupon: {
        name: '',
        applied: false
    },
    canEditDiscount: false
}

const checkoutReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case CheckoutTypes.SET_BASKET_REQUEST:
            return {
                ...state,
                basketRequest: BasketRequest.calculatePayments(action.payload)
            }
        case CheckoutTypes.SET_CAN_EDIT_DISCOUNT:
            return {
                ...state,
                canEditDiscount: action.payload
            }
        case CheckoutTypes.UPDATE_BASKET_REQUEST:
            return {
                ...state,
                basketRequest: BasketRequest.calculatePayments({
                    ...state.basketRequest,
                    ...action.payload
                })
            }
        case CheckoutTypes.SET_CONFIRMATION_DETAILS:
            return {
                ...state,
                confirmationOrder: {
                    ...action.payload
                }
            }
        case CheckoutTypes.SET_COUPON:
            return {
                ...state,
                coupon: {
                    ...action.payload
                }
            }
        case CheckoutTypes.IS_BUTTON_ENABLED:
            return {
                ...state,
                isBtnEnabled: action.payload
            }
        case CheckoutTypes.SLOT_BOOKING_LIST_COUNT:
            return {
                ...state,
                bookingCount: action.payload
            }
        case CheckoutTypes.GET_ALL_INVOICES:
            return {
                ...state,
                getAllInvoices: action.payload
            }
        case 'setcartInitialState'://use this for modifying localstorage save behaviour
            return {
                ...action.payload
            }//`set${reucerNameInCombineReducer}InitialState` is used to set initial state of reducer
        default:
            return state;
    }
};

export default checkoutReducer;