import { Taxes } from './taxes';

export class Payment {
    public static PAYMENT_TYPE_NOT_DEFINED = 0;
    public static PAYMENT_TYPE_CARD = Payment.PAYMENT_TYPE_NOT_DEFINED + 1;
    public static PAYMENT_TYPE_CASH = Payment.PAYMENT_TYPE_CARD + 1;
    public static PAYMENT_TYPE_ONLINE = Payment.PAYMENT_TYPE_CASH + 1;
    public static PAYMENT_TYPE_PACKAGE = Payment.PAYMENT_TYPE_ONLINE + 1;
    public static PAYMENT_TYPE_MOBILE = Payment.PAYMENT_TYPE_PACKAGE + 1;
    public static PAYMENT_TYPE_GOOGLE_PAY = Payment.PAYMENT_TYPE_MOBILE + 1;
    public static PAYMENT_TYPE_PAYTM = Payment.PAYMENT_TYPE_GOOGLE_PAY + 1;
    public static PAYMENT_TYPE_PHONEPE = Payment.PAYMENT_TYPE_PAYTM + 1;

    public static PAYMENT_STATUS_NOT_PAID: number = 0;
    public static PAYMENT_STATUS_PAID: number = Payment.PAYMENT_STATUS_NOT_PAID + 1;
    public static PAYMENT_STATUS_PENDING: number = Payment.PAYMENT_STATUS_PAID + 1;
    public static PAYMENT_STATUS_FAILED: number = Payment.PAYMENT_STATUS_PENDING + 1;

    public paymentStatus: number;
    public transactionId: string;
    public transactionType: number;
    public originalAmount: number;
    public packageDiscountAmount: number;
    public otherDiscountAmount: number;
    public taxationAmount: number;
    public finalAmount: number;
    public paymentId: string;
    public paymentDate: number;
    public paymentTime: number;
    public paymentSource: number;

    public paymentExpiryTimestamp: number;

    public amountPaid: number;
    public amountToBePaid: number;
    public partialPayment: Payment;

    //wallet
    public totalCashbackAmount: number;
    // public walletPaidAmount: number;
    public packageCashBackAmount: number;
    public usedWalletAmount: number;
    public usedPostWalletAmount: number;
    public taxes: Taxes;
    public platformCharges: number;
    public privilegeCardCharges: number;
    public roundOffDifference: number;

}

export const PaymentConnst = [
    { type: "Cash", value: 2 },
    { type: "Mobile", value: 5 },
    { type: "Card", value: 1 },
    { type: "PhonePe", value: 8 },
    { type: "Google Pay", value: 6 },
    { type: "PayTM", value: 7 },
];