import { connect } from 'react-redux';
import layoutActions from '../layout/layout.actions';


const initialState = { testreports: [] };
export const healthLockerType = {
    SET_TEST_REPORT: "GET_TEST_REPORT",
}
export const healthLockerAction = {
    setTestReports(item: any) {
        return (dispatch: any) => {
            dispatch({ type: healthLockerType.SET_TEST_REPORT, payload: item });
        }
    }
}
export const healthLockerReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case healthLockerType.SET_TEST_REPORT:
            return {
                ...state,
                testreports: action.payload
            };
        default:
            return state;
    }
};

export const connectHealthLocker = (Component) => {
    return connect(healthLockerReducer, { ...healthLockerAction, ...layoutActions })(Component)
};