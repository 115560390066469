import { FAQS_WITH_CATEGORY_ID, FAQS_WITH_SEARCH, FAQ_CATEGORIES } from "./faqs.types";

const faqReducer = (state: any = {}, action: any) => {
    switch (action.type) {
        case FAQ_CATEGORIES:
            return {
                ...state,
                faq_categories: action.payload
            }
        case FAQS_WITH_CATEGORY_ID:
            return {
                ...state,
                faqs_with_catID: action.payload
            }
        case FAQS_WITH_SEARCH:
            return {
                ...state,
                faqs_with_search: action.payload
            }
        default:
            return state;
    }
};

export default faqReducer;