import VideoCardTypes from './videocard.types';

export default {

    setOpenTokDetails: (payload: any) => {
        return (dispatch: any) => {
            dispatch({ type: VideoCardTypes.SET_OPENTOK_DETAILS, payload });
        };
    },

    setGCMToken: (payload: any) => {
        return (dispatch: any) => {
            dispatch({ type: VideoCardTypes.SET_GCM_TOKEN, payload });
        };
    }

}