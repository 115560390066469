import React from 'react';
import { Config } from '../../../../base/portal_env_config/config';
// import * as firebase from "firebase/app";
import firebase from "firebase/app";
import "firebase/messaging";
import { Source } from '../../../../model/base/source';
import NotificationService from './notification.service';
import { SessionBean } from '../../../../model/slotbooking/sessionbean';
import ToastComponent from '../toast/toast.component';
import videocardConnect from '../../../video/videocard.connect';
import Auth from '../../../../auth/auth';
import StorageUtil from '../../../../auth/util/storageUtil';

const firebaseConfig = {
    apiKey: "AIzaSyBa8a7ix1zagfk7k3z7uaRSbeapeXRjlH8",
    authDomain: "mymedic-portal.firebaseapp.com",
    databaseURL: "https://mymedic-portal.firebaseio.com",
    projectId: "mymedic-portal",
    storageBucket: "mymedic-portal.appspot.com",
    messagingSenderId: "297135873595",
    appId: "1:297135873595:web:162b323ab3fb4084df861e",
    measurementId: "G-G81YWH6CY4"
}

const initializedFirebaseApp = (!firebase.apps.length) ? firebase.initializeApp(firebaseConfig) : firebase.app();

class NotificationComponent extends React.Component {
    ringtone = null;//new Audio('/media/ringtone.mp3');
    state = {
        sessionBean: new SessionBean(),
    }
    props: any;
    static registration: any;
    messaging: any;
    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        if (
            (((this.props.video?.gcmToken != prevProps.video?.gcmToken) ||
                (this.props.auth?.userDetails?.profileId != prevProps.auth?.userDetails?.profileId))) &&
            this.props.auth?.userDetails?.profileId
        ) {
            NotificationService.updateGCMToken({
                "profileId": this.props.auth.userDetails.profileId,
                "source": Source.CONSUMER_PORTAL,
                "deviceToken": this.props.video.gcmToken,
                "deviceId": ""
            }).then(() => {
                console.log("Notification Token Updated in server")
            });
        }

        if (
            this.props.auth?.userDetails?.profileId != prevProps.auth?.userDetails?.profileId
        ) {
            if (this.props.auth?.userDetails?.profileId) {
                this.initNotificationsEvents();
            } else if (NotificationService.socket) {
                NotificationService.socket.close();
            }
        }
    }

    notificationTokenRefresh() {
        const self = this;
        this.messaging?.onTokenRefresh(() => {
            this.messaging?.getToken()
                .then((refreshedToken) => {
                    console.log("Token refreshed==>" + refreshedToken);
                    self.props.setGCMToken(refreshedToken);
                })

                .catch(function (err) {
                    console.log("Unable to retrieve refreshed token ", err);
                });
        });
    }

    requestPermissionForNotication() {
        const self = this;
        this.messaging?.requestPermission()
            .then(() => {
                console.log("Notification permission granted....");
                self.messaging
                    .getToken()
                    .then((currentToken) => {
                        if (currentToken) {
                            self.props.setGCMToken(currentToken);
                        } else {
                            // Show permission request.
                            console.log(
                                "No Instance ID token available. Request permission to generate one."
                            );
                        }
                    })
                    .catch(function (err) {
                        console.log("An error occurred while retrieving token.", err);
                    });
            })
            .catch(function (err) {
                console.log("Unable to get permission to notify. ", err);
            });
    }

    onNotificationMessage() {
        const self = this;
        this.messaging?.onMessage((notificationMessage) => {
            console.log("Message received. ");
            let message = JSON.parse(notificationMessage.data.default);
            console.log(message);
            self.manageNotification();
        });
    }

    manageNotification() {

    }



    static showNotification(title, options = {}) {
        let notificationOptions = {
            body: "Notification Body",
            icon: Config.portal.iconspath + 'android-icon-36x36.png',
            tag: 'simple-push-demo-notification',
            data: 'data',
            ...options
        };

        console.log(window.Notification)
        try {
            new Notification(title, {
                ...notificationOptions
            });
        } catch (error) {
            NotificationComponent.registration.showNotification(title, {
                ...notificationOptions
            });
        }
        return;
    }


    notifyMe() {

        NotificationComponent.showNotification('Vibration Sample')

        // registration.showNotification('Vibration Sample', {
        //     body: 'Buzz! Buzz!',
        //     vibrate: [200, 100, 200, 100, 200, 100, 200],
        //     tag: 'vibration-sample'
        // });
    }



    render() {
        const sessionBean = this.state.sessionBean;
        console.log("NotificationComponent", "render");
        return (
            <React.Fragment>
            </React.Fragment>
        )
    }

    acceptCall(sessionBean: SessionBean) {
        this.props.setOpenTokDetails(sessionBean);
        Auth.navigateTo('/video');
        $("#modelIdincomingcall").modal("hide");
    }
    rejectCall(sessionBean: SessionBean) {
        $("#modelIdincomingcall").modal("hide");
        NotificationService.notifyCallReject(sessionBean);
    }

    initNotificationsEvents() {
        let self = this;
        $("#modelIdincomingcall").on('shown.bs.modal', function () {
            self.ringtone.loop = true;
            $(window).focus();
            $("#playRing").click();
            try {
                self.ringtone.play();
            } catch (error) {
                console.log(error)
            }
        });
        $("#playRing").click(() => {
            self.ringtone.play();
        })
        $("#modelIdincomingcall").on("hidden.bs.modal", function () {
            StorageUtil.setStringDataLocalStorage(StorageUtil.TYPE_VIDEO_START_TIME, new Date().getTime() + '');
            self.ringtone.pause();
        });
        window.addEventListener("storage", (e) => {
            if (e.key == 'videoStartTime') {
                $("#modelIdincomingcall").modal("hide");
            }
        })

        NotificationService.initNoticationSocket();

        NotificationService.socket.on('onCallForward', (res) => {
            console.log("event", "onCallForward", res)
            let sessionBean: SessionBean = JSON.parse(res);
            if (NotificationService.avaliableStatus == true) {
                console.log(sessionBean);
                self.setState({ sessionBean });
                ($("#modelIdincomingcall") as any).modal({
                    show: true,
                    escapeClose: false,
                    clickClose: false,
                    showClose: false,
                    backdrop: "static",
                    keyboard: false
                });
            }
        });

        NotificationService.onCallReject((sessionBean) => {
            console.log(sessionBean);
            ToastComponent.warning('Call Rejected');
        })

    }

    componentDidMount() {
        if (typeof window !== "undefined") {
            try {
                console.log("NotificationComponent componentDidMount");
                if (this.props.auth?.userDetails?.profileId) {
                    this.initNotificationsEvents();
                    this.props.setGCMToken('');
                }
                try {
                    this.messaging = initializedFirebaseApp.messaging();
                } catch {
                    console.log("FireBase Error: ", this.messaging);
                }
                this.requestPermissionForNotication();
                this.notificationTokenRefresh();
                this.onNotificationMessage();
            } catch (error) {
                console.log(error);
            }
        }
    }

}

export default videocardConnect(NotificationComponent);