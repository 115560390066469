import { connect } from 'react-redux';
import checkoutActions from '../checkout/checkout.actions';
import layoutActions from '../layout/layout.actions';

const initialState = { "walletHistory": {} }
export const walletReduxTypes = {
    SET_WALLET_HISTORY: "SET_WALLET_HISTORY",
}
export const walletAction = {
    setWalletHistory(item: any) {
        return (dispatch: any) => {
            dispatch({ type: walletReduxTypes.SET_WALLET_HISTORY, payload: item });
        }
    }
}
export const walletReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case walletReduxTypes.SET_WALLET_HISTORY:
            return {
                ...state,
                walletHistory: action.payload
            };
        case 'setWalletInitialState': //use this for modifying localstorage save behaviour
            return {
                ...action.payload,
            }//`set${reucerNameInCombineReducer}InitialState` is used to set initial state of reducer
        default:
            return state;
    }
};

export const connectWallet = (Component) => {
    return connect(walletReducer, { ...walletAction, ...layoutActions, ...checkoutActions })(Component)
};