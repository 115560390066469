import VideoCardTypes from './videocard.types';

const initialState = {
    sessionBean: {
        apiKey: "",
        sessionId: "",
        tokenId: ""
    },
    gcmToken: ''
}

const videoCardReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case VideoCardTypes.SET_OPENTOK_DETAILS:
            return {
                ...state,
                sessionBean: {
                    ...action.payload
                }
            };
        case VideoCardTypes.SET_GCM_TOKEN:
            return {
                ...state,
                gcmToken: action.payload
            };
        case 'setvideoInitialState'://use this for modifying localstorage save behaviour
            return {
                ...action.payload
            }//`set${reucerNameInCombineReducer}InitialState` is used to set initial state of reducer
        default:
            return state;
    }
};

export default videoCardReducer;