import React from "react";
import { Config } from '../../base/portal_env_config/config';
import CookieConsent from "react-cookie-consent";

const Popup = () => {
  return (
    <React.Fragment>
      <style jsx global>{` 
      .cookie-widget{
        z-index: 1111111;
      }
      `}
      </style>
      <div className="cookie-widget">
        <CookieConsent
          location="bottom"
          buttonText="I Understand"
          cookieName="userconsentcookie"
          style={{ background: "#3c404d" }}
          buttonStyle={{ color: "white", fontSize: "13px", background: "#6f42c1", width: "140px" }}
          expires={150} sameSite="lax" cookieSecurity={true} enableDeclineButton={true}
        >
          We use cookies to ensure you have the best browsing experience on our website. By using our site, you acknowledge that you have read and understood our
          &nbsp;<a href={Config.portal.privacypolicy ? `//${Config.portal.privacypolicy}` : '/privacy-policy'} aria-label="privacy" style={{ color: "#a9a5a5" }}><u>Privacy Policy</u></a>&nbsp;
          &&nbsp;
          <a href={Config.portal.termsAndConditions ? `//${Config.portal.termsAndConditions}` : '/terms-condition'} aria-label="terms" style={{ color: "#a9a5a5" }}><u>Terms of Use</u></a>

        </CookieConsent>
      </div>
    </React.Fragment >
  );
};

export default Popup;
