import thunkMiddleware from 'redux-thunk';
import {
  createStore,
  applyMiddleware,
  compose
} from 'redux';
import { createLogger } from 'redux-logger';
import reducers from './reducers';
import { Config } from '../base/portal_env_config/config';

function getInitialState() {
  return {}
}

const reduxInstance = (initialState = getInitialState()): any => {
  let middlewares, common_middlewares = [thunkMiddleware];
  if (typeof window !== "undefined" && (Config.TEST_TYPE < Config.LIVE || (location.hostname == "localhost"))) {//dev && 
    middlewares = [...common_middlewares,
    createLogger()
    ]
  } else {
    middlewares = [
      ...common_middlewares
    ]
  }
  return createStore(reducers, initialState, compose(applyMiddleware(...middlewares)));
};

export default reduxInstance;