import PackageTypes from './package.types';
const packagesReducer = (state: any = {}, action: any) => {
    switch (action.type) {

        case 'setLoading':
            return {
                ...state,
                isLoading: true
            };
        case PackageTypes.SET_PACKAGE_LIST:
            return {
                ...state,
                packageList: action.payload,


            };
        case PackageTypes.SET_PACKAGE_DETAILS:
            return {
                ...state,
                packageDetails: action.payload,

            };
        case PackageTypes.SET_DOCTOR_SPECIALISTS_LIST:
            return {
                ...state,
                doctorSpecialistsOfPackage: action.payload,


            };
        case PackageTypes.SELECTED_PACKAGE:
            return {
                ...state,
                selectedPackage: action.payload,
            };
        case PackageTypes.SELECTED_PERSONAL_DOCTOR:
            return {
                ...state,
                selectedPesonalDoctorDetails: action.payload,
            };
        case PackageTypes.SET_CART_ITEM_DETAILS:
            return {
                ...state,
                setCartItemDetails: action.payload,
            };
        case 'setpackageInitialState'://use this for modifying localstorage save behaviour

            return {
                ...action.payload, packageDetails: state.packageDetails, packageList: state.packageList,
                doctorSpecialistsOfPackage: state.doctorSpecialistsOfPackage
            }//`set${reucerNameInCombineReducer}InitialState` is used to set initial state of reducer
        default:
            return state;
    }
};

export default packagesReducer;