import { GET_LOGIN_DATA, GET_POCID, GET_FREQUENT_PURCHASES, GET_SEARCH_RESULT, GET_SPECIFIC_DIAGNOSTIC_DATA, GET_SPECIFIC_HOSPITAL_DATA, GET_USER_METRICS, GET_LAST_PRESCRIPTION, GET_UPCOMING_CONSULTATION, GET_VIDEO_SESSION_DETAIL, GET_RATING_DETAILS, SET_SERVICE_CATEGORIES, GET_PHLEBO_ORDER_IN_PROGRESS, PRIVILEGE_PROFILE_DATA, PC_CARD_BENEFITS } from './dashboard.types';

const dashboardReducer = (state: any = {}, action: any) => {
    switch (action.type) {
        case GET_LOGIN_DATA:
            return {
                ...state,
                dashboardData: action.payload,
            };
        case GET_FREQUENT_PURCHASES:
            return {
                ...state,
                freqPurchases: action.payload,
            };
        case GET_USER_METRICS:
            return {
                ...state,
                userMetrics: action.payload,
            };
        case GET_SEARCH_RESULT:
            return {
                ...state,
                globalSearchResult: action.payload,
            };
        case GET_SPECIFIC_DIAGNOSTIC_DATA:
            return {
                ...state,
                diagData: action.payload,
            };
        case GET_SPECIFIC_HOSPITAL_DATA:
            return {
                ...state,
                hospitalData: action.payload,
            };
        case GET_POCID:
            return {
                ...state,
                pocId: action.payload,
            };
        case GET_LAST_PRESCRIPTION:
            return {
                ...state,
                recentPrescription: action.payload
            };
        case GET_UPCOMING_CONSULTATION:
            return {
                ...state,
                upcomingConsultation: action.payload
            }
        case GET_VIDEO_SESSION_DETAIL:
            return {
                ...state,
                sessionDetail: action.payload
            }
        case GET_RATING_DETAILS:
            return {
                ...state,
                ratingDetail: action.payload
            }
        case SET_SERVICE_CATEGORIES:
            let { categories, serviceMap } = action.payload;
            return {
                ...state,
                categoryList: categories,
                CatServiceMap: serviceMap
            }
        case GET_PHLEBO_ORDER_IN_PROGRESS:
            return {
                ...state,
                phleboOrderInProgressDetails: action.payload
            }
        case PRIVILEGE_PROFILE_DATA:
            return {
                ...state,
                privilegeProData: action.payload
            }
        case PC_CARD_BENEFITS:
            return {
                ...state,
                PCBenefits: action.payload
            }

        case 'setdashboardInitialState'://use this for modifying localstorage save behaviour
            let { dashboardData, freqPurchases, userMetrics, searchResult, hospitalData,
                diagData, recentPrescription, upcomingConsultation, phleboOrderInProgressDetails,
                privilegeProData, PCBenefits } = state;
            return {
                ...action.payload,
                // dashboardData,
                freqPurchases,
                userMetrics,
                searchResult,
                hospitalData,
                diagData,
                recentPrescription,
                upcomingConsultation,
                phleboOrderInProgressDetails,
                PCBenefits
            }//`set${reucerNameInCombineReducer}InitialState` is used to set initial state of reducer 
        default:
            return state;
    }
};

export default dashboardReducer;