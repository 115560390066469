//Dynamic Import
// const Provider = dynamic(() =>
//   import('react-redux').then(mod => mod.Provider)
// )

import { NextPageContext } from 'next';
import App from 'next/app';
import withRedux from 'next-redux-wrapper';
import { Provider } from 'react-redux';
import store from '../redux';
import { Store } from 'redux';
import Cookies from "js-cookie";

import "font-awesome/css/font-awesome.min.css";
import '../styles/base.style.scss';
import 'react-toastify/dist/ReactToastify.css';
import ToastComponent from '../components/layout/widgets/toast/toast.component';
import { CryptoUtil } from '../auth/util/cryptoutil';
import * as $ from "jquery";
import { Config } from '../base/portal_env_config/config';
import { ReduxAuth } from '../auth/reduxauth';
import Auth from '../auth/auth';
import Router from 'next/router';
import NotificationComponent from '../components/layout/widgets/notification/notification.component';
import ErrorBoundary from '../components/layout/widgets/errorboundary/errorboundary.component';
import { pagemoduleType } from '../base/pagemoduleutil/pagemodule.redux';
import RouterUtil from '../base/utils/routerUtil';
import { appWithTranslation, i18n } from '../../i18n';
import StorageUtil from '../auth/util/storageUtil';
import Popup from '../auth/util/cookiePopUp';
import { initGA, logPageView } from '../clevertap/analytics';


interface AppContext extends NextPageContext {
  store: Store;
}

class MyApp extends App<AppContext> {


  state = {
    isInitialPropsLoaded: !process.env.staticDeployment && !Config.portal.staticDeployment,
    isStaticDeployment: process.env.staticDeployment || Config.portal.staticDeployment
  };

  constructor(props) {
    super(props)
    this.initMyApp(props);
  }

  async initMyApp(props) {
    ReduxAuth.setAuthState(props.store.getState);
    ReduxAuth.setDispatchState(props.store.dispatch);
    if (typeof window !== "undefined") {
      try {
        if (
          (Config.TEST_TYPE >= Config.LIVE) &&
          (location.hostname != "localhost")
        ) {
          try {
            (window as any).hslogs = [];
            (window as any).hslog = console.log;
            console.log = (log, log2, log3) => {
              try {
                (window as any).hslogs.unshift(JSON.stringify({ log, log2, log3 }));
                if ((window as any).hslogs.length > 30)
                  (window as any).hslogs?.pop();
              } catch (error) {
                console.error(error);
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
        require('react-popper');
        require('bootstrap');
        (global as any).$ = $;
        (window as any).$ = $;
        (global as any).JQuery = $;
        (window as any).JQuery = $;
        window.onstorage = (e) => {
          if (e.key == 'logout') Auth.logout();
          if (e.key == 'a_k') {
            Auth.setLoginResponse(CryptoUtil.decryptData(e.newValue));
            if (window.location.pathname.includes('/login')) {
              Router.back();
            }
          };
        }
        let _store = StorageUtil.getDataLocalStorage(StorageUtil.TYPE_RAPTOR);
        if (_store) {
          if (_store?.auth?.portal?.storageHash == props.store.getState()?.auth?.portal?.storageHash) {
            let a_k = StorageUtil.getDataLocalStorage(StorageUtil.LOGIN_KEY);
            _store.auth.token = a_k?.sessionToken;
            _store.auth.userDetails = { ...a_k, ..._store?.auth?.userDetails };
            Object.keys(_store).forEach((key) => {
              this.props.store.dispatch({ type: 'set' + key + 'InitialState', payload: _store[key] });
            });
          } else {
            StorageUtil.clearData();
            Object.keys(Cookies.get()).forEach(function (cookieName) {
              Cookies.remove(cookieName);
            });
            if (_store?.auth?.userDetails?.profileId > 0)
              Auth.logout(JSON.parse(JSON.stringify({
                profileId: _store.auth.userDetails.profileId,
                deviceToken: _store.video.gcmToken
              })));
          }
        }
        ($ as any)(window).on('popstate', function (event) {
          (($ as any)(".modal")).modal('hide');
          ($ as any)('.modal-backdrop').remove();
        });
      } catch (error) {
        console.error(error);
      }
      if (this.state.isStaticDeployment) {
        // console.log('defaultLanguage' + Config.portal.defaultLanguage);
        // i18n.changeLanguage(Config.portal.defaultLanguage);
        if (this.props.Component && this.props.Component.getInitialProps) {
          let props = { ...this.props };
          props.query = RouterUtil.getQuery();
          await this.props.Component.getInitialProps(props);
        }
        this.setState({ isInitialPropsLoaded: true });
      }
    }
  }

  componentDidMount() {
    // initGA();
    // logPageView();
    if (typeof window !== "undefined") {
      let self = this;
      window.addEventListener("beforeunload", function (event) {
        event.preventDefault();
        let state = self.props.store.getState();
        delete state.layout;
        StorageUtil.setDataLocalStorage(StorageUtil.TYPE_RAPTOR, state);
        StorageUtil.setStringDataLocalStorage(StorageUtil.TYPE_TAB_CLOSE_TIME, new Date().getTime().toString());
      });
    }
    // lets add initial route to `history`
    const { asPath } = this.props.router;
    this.setPathHistory(asPath);
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    // if current route (`asPath`) does not equal
    // the latest item in the history,
    // it is changed so lets save it
    const { asPath } = this.props.router;
    let storeData = this.props?.store?.getState();
    let history = storeData?.basepagemodule?.history
    if (history[0] !== asPath) {
      this.setPathHistory(asPath);
    }
  }

  setPathHistory(asPath) {
    this.props?.store?.dispatch({ type: pagemoduleType.UPDATE_HISTORY, payload: asPath });
  }

  render() {
    const { store, Component, ...props } = this.props;
    return (
      <Provider store={store}>
        {/* <Meta {...props}></Meta> */}
        {((this.state.isInitialPropsLoaded && typeof window !== "undefined") || !this.state.isStaticDeployment) && <Component  {...props} />}
        {typeof window !== "undefined" && <ErrorBoundary><NotificationComponent /></ErrorBoundary>}
        {!((this.state.isInitialPropsLoaded && typeof window !== "undefined") || !this.state.isStaticDeployment) ? 'Loading . . . ' : ' '}
        <ToastComponent></ToastComponent>
        {((this.state.isInitialPropsLoaded && typeof window !== "undefined") || !this.state.isStaticDeployment) && <Popup />}
        <script src={`https://maps.googleapis.com/maps/api/js?key=${Config.portal?.googleMapAPIKey ? Config.portal?.googleMapAPIKey : "AIzaSyC4azfkWH233PFGoizTQq4JBPbEPGC1HKU"}&libraries=places&v=weekly`} defer></script>
      </Provider>
    );
  }
}


export default (withRedux(store)(appWithTranslation(MyApp)));