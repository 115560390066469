import { ACCOUNT_DELETED, CART_SELECTED_ADDRESS, GET_INTERESTS, GET_LANGUAGES, GET_PROFILE_DETAILS, USER_ADDRESSES } from "./profile.types";

const profileReducer = (state: any = {}, action: any) => {

    switch (action.type) {
        case GET_PROFILE_DETAILS:
            return {
                ...state,
                profileDetails: action.payload,
            }
        case GET_LANGUAGES:
            return {
                ...state,
                langList: action.payload,
            }
        case GET_INTERESTS:
            return {
                ...state,
                interestList: action.payload,
            }
        case USER_ADDRESSES:
            return {
                ...state,
                userAddressList: action.payload,
            }
        case CART_SELECTED_ADDRESS:
            return {
                ...state,
                selectedAddress: action.payload,
            }
        case ACCOUNT_DELETED:
            return {
                ...state,
                isAccountDeleted: true,
            }

        case 'setprofileInitialState': {//use this for modifying localstorage save behaviour
            let { isAccountDeleted } = state;
            return {
                ...action.payload,
                isAccountDeleted
            }//`set${reucerNameInCombineReducer}InitialState` is used to set initial state of reducer 
        }

        default:
            return state;
    }
};

export default profileReducer;