import { BaseResponse } from '../../../../model/base/baseresponse';
import AxiosService from '../../../../base/portal_env_config/axiosService';
import { paths } from '../../../../base/portal_env_config/urlconstants';
import * as io from 'socket.io-client';
import Auth from '../../../../auth/auth';
import { SessionBean } from '../../../../model/slotbooking/sessionbean';
import { SocketConstants } from '../../../../model/socket/socketconstants';


export default class NotificationService {

    public static socket: SocketIOClient.Socket;
    static avaliableStatus: boolean = true;


    public static async updateGCMToken(req): Promise<BaseResponse> {
        return AxiosService.httpPost(paths.UPDATE_GCM_TOKEN, req).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static initNoticationSocket(profileId = Auth.getUserDetails()?.profileId) {
        console.log("initNoticationSocket " + profileId,"path",paths.NOTIFICATION_SOCKET);
        if (profileId) {
            NotificationService.socket = io.connect(paths.NOTIFICATION_SOCKET);
            NotificationService.socket.emit('subscribeUserNotification', profileId);
        }
    }

    public static subscribeNotification(profileId = Auth.getUserDetails().profileId) {
        NotificationService.socket.emit('subscribeUserNotification', profileId);
    }

    public static onCallForward(callBack: any) {
        console.log("NotificationService onCallForward",this.socket)
        this.socket.on('onCallForward', (res) => {
            console.log("event", "onCallForward", res)
            let sessionBean: SessionBean = JSON.parse(res);
            if (NotificationService.avaliableStatus == true) {
                callBack(sessionBean);
            }
        });
    }

    public static onCallReject(callBack: any) {
        NotificationService.socket.on('onCallReject', (res) => {
            console.log("event", "onCallReject", res)
            let sessionBean: SessionBean = JSON.parse(res);
            callBack(sessionBean);
        });
    }

    public static notifyCallReject(sessionBean: SessionBean) {
        if (sessionBean) {
            NotificationService.socket.emit('notifyDoctorCallReject', JSON.stringify(sessionBean));
        }
    }

    public static notifyDoctorCallForward(sessionBean: SessionBean) {
        sessionBean.to = SocketConstants.DOCTOR;
        NotificationService.socket.emit('notifyDoctorCallForward', JSON.stringify(sessionBean));
    }
}